import React from 'react'; // , { useState }

// react theme/style
import { makeStyles } from '@material-ui/core'; // useTheme,

// MUI core
import { Grid } from '@material-ui/core'; // Link Button, Paper, Avatar, Typography,

// MUI icons

// Gatsby
//import { Link, graphql } from 'gatsby';

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel

// Local : see src/index.js for available components
import { FourOFour } from '../../src';

const useStyles = makeStyles(theme => {
  return {
    page: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  };
});

// NOTE: The 404 page must be as 404.js (and NOT 404.jsx - does not get picked up by gatsby when 404 error occurs)
//
// Compose Page Sections
//
const NotFoundPage = props => {
  //const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <FourOFour />
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFoundPage;
